"use client";
// import Link from "next/link";
// import { usePathname, useRouter } from "next/navigation";
import { usePathname } from "next/navigation";

// import { drawMonogram } from "@/utils/design-elements";
// import { getCurrentYear, normalizePath } from "@/utils/helpers";
import { getCurrentYear } from "@/utils/helpers";
// import { drawIcon, socialLinks } from "@/utils/social-buttons";

export default function BottomSheet(): React.JSX.Element {
  // const router = useRouter();
  const pathname = usePathname();
  // const subscribeHref = `${pathname}?subscribe`;

  console.log("pathname:", pathname);

  // const handleSubscribeClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
  //   e.preventDefault();
  //   try {
  //     router.push(subscribeHref);
  //   } catch (error) {
  //     console.error("Navigation failed:", error);
  //   }
  // };

  return (
    <div className="bottom-sheet">
      {/* <div className="bottom-sheet-cell bottom-sheet-cell-brandmark">
        <Link
          aria-current={normalizePath(pathname) === normalizePath("/jmf") ? "page" : undefined}
          className="monogram"
          href="/jmf"
          style={{ cursor: "default" }}
          onClick={(event) => {
            event.preventDefault();
          }}
        >
          {drawMonogram({
            size: 32,
          })}
        </Link>
      </div> */}
      <div className="bottom-sheet-cell bottom-sheet-cell-copyright">
        {" "}
        &#169; {process.env.NEXT_PUBLIC_COPYRIGHT_HOLDER || "Jeffrey Modell Foundation"}{" "}
        {getCurrentYear()}
      </div>
      {/* <div className="bottom-sheet-cell bottom-sheet-cell-social">
        <ul className="social" data-label="Connect with us on social media">
          {socialLinks.map((link) => (
            <li className={link.name} key={link.href}>
              <a
                aria-label={`Follow us on ${link.title}`}
                className="gtm-join-group"
                data-gtm-param-group-id={link.group_id}
                href={link.href}
                rel="nofollow noreferrer"
                role="button"
                target="_blank"
                title={link.title}
              >
                {drawIcon(link.name)}
              </a>
            </li>
          ))}
        </ul>
      </div> */}
      {/* <div className="bottom-sheet-cell bottom-sheet-cell-newsletter">
        <a
          className="footer-link"
          href={subscribeHref}
          aria-current={
            normalizePath(pathname) === normalizePath(subscribeHref) ? "page" : undefined
          }
          onClick={handleSubscribeClick}
        >
          Subscribe to AIPI
        </a>
      </div> */}
    </div>
  );
}
