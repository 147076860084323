"use client";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { useEffect, useRef, useState } from "react";

import { drawBrandmark } from "@/utils/design-elements";
// import SubscribeSheet from "@/components/sheets/subscribe-sheet";
import { normalizePath } from "@/utils/helpers";
import { drawIcon } from "@/utils/social-buttons";

interface MenuItem {
  label: string;
  link: string;
}

const menuItems: MenuItem[] = [
  { label: "Meeting", link: "/meeting" },
  { label: "Publishing", link: "/publishing" },
  { label: "About", link: "/about" },
];

export default function TopAppBar(): React.JSX.Element {
  const pathname = usePathname();
  // https://nextjs.org/docs/app/api-reference/functions/use-search-params
  const searchParams = useSearchParams();
  const router = useRouter();
  const subscribeParam = searchParams.has("subscribe");

  const [isSubscribeSheetVisible, setIsSubscribeSheetVisible] = useState(false);

  useEffect(() => {
    setIsSubscribeSheetVisible(Boolean(subscribeParam) === true);
  }, [subscribeParam]);

  // const toggleSubscribeSheetVisibility = (): void => {
  //   const newVisibility = !isSubscribeSheetVisible;
  //   setIsSubscribeSheetVisible(newVisibility);
  //   if (newVisibility) {
  //     router.push(`${pathname}?subscribe`, { scroll: false });
  //   } else {
  //     router.push(pathname, { scroll: false });
  //   }
  // };

  // const closeSubscribeSheet = (): void => {
  //   setIsSubscribeSheetVisible(false);
  //   router.push(pathname, { scroll: false });
  // };

  const formatLabel = (label: string): string => {
    return label.toLowerCase().replace(/\s+/g, "-");
  };

  const formatClassName = (link: string): string => {
    return link.replace(/\//g, "").toLowerCase(); // Remove slashes and convert to lowercase
  };

  const subscribeSheetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (subscribeSheetRef.current && !subscribeSheetRef.current.contains(event.target as Node)) {
        setIsSubscribeSheetVisible(false);
        router.push(pathname, { scroll: false });
      }
    };

    if (isSubscribeSheetVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return (): void => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSubscribeSheetVisible, router, pathname]);

  return (
    <>
      {/* <SubscribeSheet
        isVisible={isSubscribeSheetVisible}
        ref={subscribeSheetRef}
        onClose={closeSubscribeSheet}
      /> */}
      <nav aria-label="Site navigation" className="top-app-bar">
        <ul>
          <li className="brandmark-row">
            <Link
              aria-current={normalizePath(pathname) === normalizePath("/") ? "page" : undefined}
              aria-label="Link to the home page"
              className="brandmark"
              href="/"
            >
              {drawBrandmark()}
            </Link>
            <span className="descriptor">
              Artificial Intelligence and <em>Primary Immunodeficiency</em>
            </span>
          </li>
          <li className="top-app-bar-utilities home subscribe">
            <ul className="nested-top-app-bar-menu">
              <li className="home">
                <Link
                  aria-current={normalizePath(pathname) === normalizePath("/") ? "page" : undefined}
                  aria-label="Link to the home page"
                  className="link"
                  href="/"
                >
                  <em className="icon">{drawIcon("home")}</em> <em className="label">Home</em>
                </Link>
              </li>
              {/* <li className="subscribe">
                <button className="link" type="button" onClick={toggleSubscribeSheetVisibility}>
                  <em className="icon">{drawIcon("notifications")}</em>{" "}
                  <em className="label">Subscribe</em>
                </button>
              </li> */}
            </ul>
          </li>
          {menuItems.map((item, index) => (
            <li data-page={formatLabel(item.label)} key={index}>
              <Link
                className={`link primary-link ${formatClassName(item.link)}`}
                href={item.link}
                aria-current={
                  normalizePath(pathname) === normalizePath(item.link) ? "page" : undefined
                }
              >
                {item.label}
              </Link>
            </li>
          ))}
          <li className="top-app-bar-utilities">
            <ul className="nested-top-app-bar-menu">
              <li className="instagram">
                <a
                  aria-label="Link to Instagram"
                  className="link"
                  href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <em className="icon">{drawIcon("instagram")}</em>{" "}
                  <em className="label">Instagram</em>
                </a>
              </li>
              <li className="twitter">
                <a
                  aria-label="Link to Twitter/X"
                  className="link"
                  href={process.env.NEXT_PUBLIC_TWITTER_URL}
                  rel="noreferrer"
                  target="_blank"
                >
                  <em className="icon">{drawIcon("twitter")}</em> <em className="label">X</em>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
}
