import React from "react";

interface SocialLink {
  callToAction: string;
  displayName: string;
  group_id: string;
  href: string | undefined;
  name: string;
  title: string;
}

export const socialLinks: SocialLink[] = [
  {
    callToAction: "",
    displayName: "Instagram",
    group_id: "Instagram",
    href: process.env.NEXT_PUBLIC_INSTAGRAM_URL,
    name: "instagram",
    title: "Follow us on Instagram",
  },
  {
    callToAction: "",
    displayName: "Twitter",
    group_id: "Twitter",
    href: process.env.NEXT_PUBLIC_TWITTER_URL,
    name: "twitter",
    title: "Follow us on Twitter",
  },
];

type IconLookup = Record<string, () => string>;

const iconLookup: IconLookup = {
  facebook: () =>
    "M32,16.1a16,16,0,1,0-32,0A16.2,16.2,0,0,0,13.5,32V20.7h-4V16.1h4V12.5c0-4,2.4-6.2,6.1-6.2a27,27,0,0,1,3.6.3v3.9h-2a2.4,2.4,0,0,0-2.6,2.6v3h4.5l-.8,4.6H18.6V32A16.1,16.1,0,0,0,32,16.1",
  home: () =>
    "M18.2,16.7h0s-8.4,14.8-8.4,14.8c-.3.5-.9.6-1.3.4l-1-.6c-.5-.3-.6-.9-.4-1.4l8.9-15.6h0s0,0,0,0l10.7-5.5c.5-.2,1.1,0,1.3.4l.5,1c.2.5,0,1.1-.4,1.3l-10,5.2h0ZM29.9,14.4l-.5-1c-.2-.5-.8-.7-1.3-.4l-7.8,4.1c-.5.2-.7.8-.4,1.3l.5,1c.2.5.8.7,1.3.4l7.8-4.1c.5-.2.7-.8.4-1.3ZM13.6,13.1h0l-.7-12.1c0-.6-.5-1-1.1-.9h-1.1c-.6.1-1,.6-.9,1.1l.6,11.2L2.1,27.1c-.3.5-.1,1.1.4,1.4l1,.6c.5.3,1.1.1,1.4-.4l8.9-15.6h0ZM7.1,11.8h1.1c.6-.1,1-.6.9-1.1l-.5-8.8c0-.6-.5-1-1.1-.9h-1.1c-.6.1-1,.6-.9,1.1l.5,8.8c0,.6.5,1,1.1.9Z",
  instagram: () =>
    "M31.9 9.4c0-1.3-.3-2.6-.7-3.9-.4-1.1-1-2-1.8-2.8-.8-.8-1.8-1.4-2.8-1.8C25.4.4 24 .2 22.7.2 20.9 0 20.3 0 16 0c-4.3 0-4.9 0-6.6.1-1.3 0-2.6.3-3.9.7-1.1.4-2 1-2.8 1.8-.8.9-1.4 1.9-1.8 2.9C.4 6.8.1 8.1.1 9.4 0 11.1 0 11.7 0 16s0 4.9.1 6.6c0 1.3.3 2.6.7 3.9.4 1.1 1 2 1.8 2.8.8.8 1.8 1.4 2.8 1.8 1.2.5 2.6.7 3.9.7 1.8.2 2.4.2 6.7.2 4.3 0 4.9 0 6.6-.1 1.3 0 2.6-.3 3.9-.7 2.1-.8 3.8-2.5 4.7-4.7.5-1.2.7-2.6.7-3.9.1-1.7.1-2.2.1-6.6s0-4.9-.1-6.6zM29 22.5c0 1-.2 2-.5 3-.5 1.4-1.6 2.5-3 3-1 .4-2 .5-3 .5-1.7.1-2.2.1-6.5.1s-4.8 0-6.5-.1c-1 0-2-.2-3-.5-1.4-.5-2.5-1.6-3-3-.4-1-.5-2-.5-3-.1-1.7-.1-2.2-.1-6.5s0-4.8.1-6.5c0-1 .2-2 .5-3 .5-1.4 1.6-2.5 3-3 1-.4 2-.5 3-.5 1.7-.1 2.2-.1 6.5-.1s4.8 0 6.5.1c1 0 2 .2 3 .5.7.3 1.3.7 1.8 1.2s.9 1.1 1.2 1.8c.4 1 .5 2 .5 3 0 1.7.1 2.2.1 6.5s0 4.8-.1 6.5zM16 7.8c-4.5 0-8.2 3.7-8.2 8.2s3.7 8.2 8.2 8.2c4.5 0 8.2-3.7 8.2-8.2S20.5 7.8 16 7.8zm0 13.5c-3 0-5.4-2.4-5.4-5.4 0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4zM26.5 7.5c0 1.1-.9 1.9-1.9 1.9-1.1 0-1.9-.9-1.9-1.9 0-1.1.9-1.9 1.9-1.9 1-.1 1.9.8 1.9 1.9 0-.1 0 0 0 0z",
  linkedin: () =>
    "M.5,10.6H7.2V32H.5ZM3.8,0A3.9,3.9,0,0,1,7.7,3.9,3.8,3.8,0,0,1,3.8,7.7,3.8,3.8,0,0,1,0,3.9H0A3.9,3.9,0,0,1,3.8,0h0m7.5,10.6h6.4v2.9h.1A6.9,6.9,0,0,1,24,10.1c6.8,0,8,4.4,8,10.2V32H25.4V21.6c0-2.5,0-5.7-3.5-5.7S18,18.7,18,21.4V32H11.3Z",
  notifications: () =>
    "M3.2 27.2V24h3.2V12.8c0-2.2.7-4.2 2-5.9s3.1-2.8 5.2-3.4V2.4c0-.7.2-1.2.7-1.7s1-.7 1.7-.7 1.2.2 1.7.7c.5.5.7 1 .7 1.7v1.1c2.1.5 3.9 1.7 5.2 3.4s2 3.7 2 5.9V24h3.2v3.2H3.2zM16 32c-.9 0-1.6-.3-2.3-.9s-.9-1.4-.9-2.3h6.4c0 .9-.3 1.6-.9 2.3s-1.4.9-2.3.9zm-6.4-8h12.8V12.8c0-1.8-.6-3.3-1.9-4.5S17.7 6.4 16 6.4s-3.3.6-4.5 1.9-1.9 2.8-1.9 4.5V24z",
  tiktok: () =>
    "M23.6 9.1c-.2 0-.3-.2-.5-.3-.4-.3-.9-.6-1.2-1-.9-1-1.2-2.1-1.4-2.8-.1-.6 0-1 0-1h-4v16.5c0 1.2-.7 2.2-1.7 2.8-.5.3-1.1.5-1.6.4-1.8 0-3.4-1.6-3.4-3.5s1.5-3.5 3.4-3.5c.4 0 .7 0 1.1.2v-4.2c-2-.3-4.2.4-5.7 1.7-.7.6-1.3 1.4-1.7 2.2-.2.3-.8 1.5-.9 3.5 0 1.1.3 2.3.4 2.7 0 .3.5 1.2 1.1 2.1.5.6 1.1 1.2 1.7 1.7 1.9 1.4 4.1 1.3 4.1 1.3.4 0 1.6 0 3-.7 1.5-.8 2.4-1.9 2.4-1.9.6-.7 1.1-1.5 1.4-2.3.4-1 .5-2.2.5-2.6V12l.7.5s1 .6 2.4 1c1.1.3 2.4.3 2.4.3v-4c-.5 0-1.4-.1-2.5-.6v-.1z",
  twitter: () =>
    "M19 13.6L30.9 0h-2.8L17.8 11.8 9.5 0H0l12.5 17.8L0 32h2.8l10.9-12.4L22.4 32h9.5M3.9 2.1h4.3L28.1 30h-4.3",
  youtube: () =>
    "M31.4,8.3a4.4,4.4,0,0,0-2.9-2.9C26.1,4.8,16,4.8,16,4.8s-10.1,0-12.5.6A4.2,4.2,0,0,0,.6,8.3,47,47,0,0,0,0,16a45.7,45.7,0,0,0,.6,7.7,4.4,4.4,0,0,0,2.9,2.9c2.6.6,12.5.6,12.5.6s10.1,0,12.5-.6a4.4,4.4,0,0,0,2.9-2.9A45.7,45.7,0,0,0,32,16,47,47,0,0,0,31.4,8.3ZM12.8,20.8V11.2L21.1,16Z",
};

export function drawIcon(iconName: string): React.ReactElement | null {
  const icon = iconLookup[iconName];

  if (!icon) {
    console.warn(`Icon "${iconName}" not found`);
    return null;
  }

  const viewBoxSize = 32;
  const iconSize = 22;

  return (
    <svg
      height={iconSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={iconSize}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={icon()} />
    </svg>
  );
}

drawIcon.displayName = "DrawIcon";

export default drawIcon;
