import React, { useMemo } from "react";

const WORDS = ["aimlpi", "aipi"] as const;
const REPEAT_COUNT = 4;

const Words: React.FC = () => {
  const gridItems = useMemo(() => {
    return Array.from({ length: REPEAT_COUNT }, (_, rowIndex) =>
      WORDS.map((word, colIndex) => ({
        className: `grid-cell-${rowIndex * WORDS.length + colIndex + 1}`,
        key: `${rowIndex}-${colIndex}`,
        word,
      })),
    ).flat();
  }, []);

  return (
    <div className="word-grid">
      <ul className="word-grid-list">
        {gridItems.map(({ word, className, key }) => (
          <li className={className} key={key}>
            {word}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(Words);
