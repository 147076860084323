import React, { useCallback, useEffect, useMemo, useState } from "react";

const MIN_DIGITS = 80;
const MAX_DIGITS = 160;
const BRIGHT_COUNT = 10;
const ELECTRIC_COUNT = 5;
const COLUMN_WIDTH = 12; // pixels
const MIN_COLUMN_LENGTH = 30;
const MAX_COLUMN_LENGTH = 60;
const DEBOUNCE_DELAY = 200; // milliseconds

interface BinaryCodeColumnProps {
  length?: number;
}

const generateRandomBinaryDigit = (): "0" | "1" => (Math.random() < 0.5 ? "0" : "1");

const selectRandomIndices = (numIndices: number, maxIndices: number): number[] => {
  const indices = new Set<number>();
  while (indices.size < Math.min(numIndices, maxIndices)) {
    indices.add(Math.floor(Math.random() * maxIndices));
  }
  return Array.from(indices);
};

const BinaryCodeColumn: React.FC<BinaryCodeColumnProps> = React.memo(
  ({ length }: BinaryCodeColumnProps) => {
    const numDigits = useMemo(
      () => length ?? Math.floor(Math.random() * (MAX_DIGITS - MIN_DIGITS + 1)) + MIN_DIGITS,
      [length],
    );

    const { binaryDigits, brightIndices, electricIndices } = useMemo(() => {
      const digits = Array.from({ length: numDigits }, generateRandomBinaryDigit);
      const bright = selectRandomIndices(BRIGHT_COUNT, numDigits);
      let electric;
      do {
        electric = selectRandomIndices(ELECTRIC_COUNT, numDigits);
      } while (electric.some((index) => bright.includes(index)));

      return { binaryDigits: digits, brightIndices: bright, electricIndices: electric };
    }, [numDigits]);

    return (
      <li>
        {binaryDigits.map((digit, index) => {
          const className = [
            brightIndices.includes(index) && "bright",
            electricIndices.includes(index) && "electric",
          ]
            .filter(Boolean)
            .join(" ");
          return (
            <em className={className} key={index}>
              {digit}
            </em>
          );
        })}
      </li>
    );
  },
);

BinaryCodeColumn.displayName = "BinaryCodeColumn";

function debounce<T extends (...args: unknown[]) => unknown>(
  func: T,
  wait: number,
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: Parameters<T>): void => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

const BinaryCodeColumnsContainer: React.FC = () => {
  const [columns, setColumns] = useState<React.JSX.Element[]>([]);

  const generateBinaryCodeColumns = useCallback(() => {
    const windowWidth = window.innerWidth;
    const numColumns = Math.ceil(windowWidth / COLUMN_WIDTH);
    return Array.from({ length: numColumns }, (_, index) => {
      const randomLength =
        Math.floor(Math.random() * (MAX_COLUMN_LENGTH - MIN_COLUMN_LENGTH + 1)) + MIN_COLUMN_LENGTH;
      return <BinaryCodeColumn key={index} length={randomLength} />;
    });
  }, []);

  const debouncedGenerateColumns = useMemo(
    () =>
      debounce(() => {
        setColumns(generateBinaryCodeColumns());
      }, DEBOUNCE_DELAY),
    [generateBinaryCodeColumns],
  );

  const handleResize = useCallback(() => {
    debouncedGenerateColumns();
  }, [debouncedGenerateColumns]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return function cleanup(): void {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return <ul className="binary-code">{columns}</ul>;
};

export default BinaryCodeColumnsContainer;
