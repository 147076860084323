"use client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

import AntibodiesContainer from "@/components/surfaces/antibodies";
import BinaryCodeColumnsContainer from "@/components/surfaces/binary-code";
import Words from "@/components/surfaces/words";

/* ----------------------------------------------- */
export default function ViewportSurface(): React.ReactElement {
  const pathname = usePathname();

  useEffect(() => {
    document.body.setAttribute("data-is-loaded", "true");
  }, []);
  return (
    <div className="viewport-surface" data-route={pathname}>
      <BinaryCodeColumnsContainer />
      <Words />
      <AntibodiesContainer />
    </div>
  );
}
